<template>
  <div>
    <BModal
    v-model="open"
    size="lg"
    id="modal"
    hide-footer
    title="Код размещения"
    @hide="closeModal"
    >
      <div v-if="type === 'code'">
        <CodeEditor :read_only="true" :value="installCode"
        width="100%"></CodeEditor>
      </div>

      <div v-if="type === 'vast'">
        <b-form-radio-group
            v-model="domain"
            :options="domains"
            name="domain"
        ></b-form-radio-group>
        <div class="d-flex mt-2 justify-content-between align-items-center">
       <code class="vast-url">{{vastUrl}}</code>
        <b-button @click="copyURL">Скопировать</b-button>
        </div>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss">

</style>

<script>
import CodeEditor from 'simple-code-editor';
import {BModal, BFormRadioGroup} from "bootstrap-vue"
export default {
  name: 'EmbedCodeModal',
  props: {
    type: {
      type: String,
      default: 'code',
    },
    id: {
      type: Number,
      required: false,
      default: 6,
    }
  },
  computed: {
    vastUrl() {
      return `https://${this.domain}/v1/tag/?id=${this.id}&vast=1`
    }
  },
  components: {
    BModal,
    CodeEditor,
  },
  data() {
    return {
      open: true,
      domains: ['s24tv.net', 'net.sputnik24.tv'],
      domain: 's24tv.net',
      installCode: `
<div id="player"></div>

<script src="https://player.sputnik24.tv/embed/embed.js"><\/script>

window.sputnikInit({
  // Select your own element
  targetElement: document.querySelector('#player'),
  id: ${this.id},
  type: 'affiliate'
  })`
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.vastUrl)
      } catch(err) {
        alert('Ваш браузер не поддерживает копирование, пожалуйста скопируйте вручную')
      }
    }
  }
}
</script>
<style scoped>
.vast-url {
  font-size: 16px;
  color: black;
}
</style>
